import React from 'react';
import { get } from 'lodash';
import { Alert, IconButton, useFormGroup } from '@tripledotstudios/react-core';
import { Badge, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { useFormContext } from 'react-hook-form';

import { useOccurrencesDetailsQuery } from '../queries';

export default function OccurrencesDetails() {
  const { getValues } = useFormContext();
  const { generateName } = useFormGroup();
  const detailsPath = generateName('occurrencesDetails');
  const variantIndex = parseInt(detailsPath.split('.')[1], 10);
  const initialDetails = get(getValues(), detailsPath);
  const { data, isLoading, refetch } = useOccurrencesDetailsQuery(variantIndex, getValues());
  const details = data || initialDetails;
  return (
    <>
      <div className="d-flex">
        <FontAwesomeIcon icon={faCalendar} size="xl" />
        <div className="ms-2">
          <b>Occurrences Details:</b>
          {isLoading
            ? <Spinner />
            : details && (
              <div className="ms-2">
                {details.isValid ? (
                  <>
                    <div>{details.displayStatus}</div>
                    {details.isRunning && <div>{`Current occurrence: ${details.currentNumber}`}</div>}
                    {details.isRunning && <div>{`Current occurrence ID: ${details.currentId || '-'}`}</div>}
                    {details.remainingOccurrences > -1
                      && <div>{`Remaining occurrences: ${details.remainingOccurrences}`}</div>}
                    {details.nextOccurrences.length > 0 && (
                      <>
                        <div>Next Occurrences:</div>
                        {details.nextOccurrences.map(({ startAt, endAt }) => (
                          <div key={startAt}>
                            <Badge className="ms-2" bg="primary">{`${startAt} - ${endAt}`}</Badge>
                          </div>
                        ))}
                      </>
                    )}
                  </>
                ) : 'Invalid configuration'}
              </div>
            )}
          <IconButton.Restore className="mt-2" type="button" onClick={refetch}>Refresh</IconButton.Restore>
        </div>
      </div>
      <Alert variant="warning" className="mt-3">
        <div className="ms-2 d-flex align-items-center">
          <div className="flex-grow-1">
            Changes in the configuration are not automatically calculated. Please use the &#39;Refresh&#39; button to
            see how the changes you made will affect future event occurrences.
          </div>
        </div>
      </Alert>
    </>
  );
}

import { FormGroup } from '@tripledotstudios/react-core';

import tooltipFor from '@components/creatives/CreativesTooltipForLayout';
import { DiffWrapper } from '@components/resource';
import { useAppData } from '@/hooks';
import { InUse } from '@/components';
import {
  Input,
  LabeledSwitch,
  LabeledSelect,
  LabeledInput,
  Label,
} from '@/components/resource';
import { toOptions } from '@/services/utils';
import CreativePersonalOffer from './CreativePersonalOffer';

export default function CreativeLayout({
  creativeLayoutsDataAttributes,
  layoutDataAttributeName,
  availabilityCap,
  sequentialOffersOptions,
  template,
  localisationKeys,
  appLinkConfigurations,
}) {
  const {
    enums: {
      'Creatives::LayoutTypesWithReduceAvailability': withReduceAvailability,
      'Creatives::LayoutTypes': layoutTypes,
    },
    featureToggles: {
      localisations_server_enabled: localisationsServerEnabled,
    },
  } = useAppData();
  const typesWithReduceAvailability = Object.values(withReduceAvailability);

  const localisationKeysOptions = toOptions(localisationKeys, {
    transform: (option, { availabilityStateLive, availabilityState }) => Object.assign(option, {
      availabilityStateLive,
      availabilityState,
    }),
  });
  const appLinkConfigurationsOptions = toOptions(appLinkConfigurations);

  return (
    <>
      {creativeLayoutsDataAttributes.map((creativeLayoutsData, index) => creativeLayoutsData._destroy || (
        <FormGroup
          key={creativeLayoutsData.key}
          name={`${layoutDataAttributeName}.${creativeLayoutsData.realIndex || index}`}
        >
          <Input type="hidden" name="internalName" />
          <Input type="hidden" name="layoutType" />

          {creativeLayoutsData.layoutType === layoutTypes.OFFER_CONTAINER
            && (
              <FormGroup name="creativePersonalOfferAttributes">
                <Label
                  label={creativeLayoutsData.internalName}
                  tooltipText={tooltipFor(template, creativeLayoutsData.internalName)}
                >
                  <CreativePersonalOffer />
                </Label>

              </FormGroup>
            )}

          {creativeLayoutsData.layoutType === layoutTypes.OFFER_CONTAINER_GROUP
            && (
              <DiffWrapper for="entityId">
                <LabeledSelect
                  tooltipText={tooltipFor(template, creativeLayoutsData.internalName)}
                  label={creativeLayoutsData.internalName}
                  name="entityId"
                  options={sequentialOffersOptions}
                />
              </DiffWrapper>
            )}

          {creativeLayoutsData.layoutType === layoutTypes.APP_LINK_BUTTON
            && (
              <DiffWrapper for="entityId">
                <LabeledSelect
                  tooltipText={tooltipFor(template, creativeLayoutsData.internalName)}
                  label={creativeLayoutsData.internalName}
                  name="entityId"
                  options={appLinkConfigurationsOptions}
                />
              </DiffWrapper>
            )}

          {creativeLayoutsData.layoutType === layoutTypes.TEXT && (
            <DiffWrapper for="value">
              <LabeledInput
                tooltipText={tooltipFor(template, creativeLayoutsData.internalName)}
                label={creativeLayoutsData.internalName}
                name="value"
              />
            </DiffWrapper>
          )}

          {creativeLayoutsData.layoutType === layoutTypes.TEXT_LOCALISED && (
            <DiffWrapper for="entityId">
              <LabeledSelect
                tooltipText={tooltipFor(template, creativeLayoutsData.internalName)}
                label={creativeLayoutsData.internalName}
                name="entityId"
                options={localisationKeysOptions}
                formatOptionLabel={localisationsServerEnabled ? InUse.AvailabilityStateOptionLabelFormat : undefined}
              />
            </DiffWrapper>
          )}
          {typesWithReduceAvailability.indexOf(creativeLayoutsData.layoutType) > -1 && +availabilityCap > 0 && (
            <DiffWrapper for="reduceAvailability">
              <LabeledSwitch name="reduceAvailability" label="Reduce Availability" />
            </DiffWrapper>
          )}
        </FormGroup>
      ))}
    </>
  );
}

import React from 'react';
import { useRouter, updateQuery } from '@tripledotstudios/react-core';

import {
  useQuery, useExtendedFieldArray, useAppData, useCurrentApplication,
} from '@hooks';
import { useEventsConfigurationsOptions } from '@pages/events/configurations/queries';
import { useSequentialOffersOptions } from '@pages/campaigns/sequential_offers/queries';
import { SegmentationRoutes, PaymentsRoutes, CreativesRoutes } from '@pages/routes';
import { SegmentsControl } from '@components/segmentation';
import { RegisteredError } from '@components/resource';

import Tabs from './Tabs';

export default function Segments() {
  const router = useRouter();
  const currentApplication = useCurrentApplication();
  const { query } = router;
  const { enums } = useAppData();
  const activityTypes = enums['Events::ActivityTypes'];
  const eventsConfigurationStatuses = enums['Events::ConfigurationStatuses'];

  const { response: presets } = useQuery(
    PaymentsRoutes.Presets.indexRequest,
    { ...query, withoutPagination: true },
  );
  const { response: triggers } = useQuery(
    SegmentationRoutes.Triggers.indexRequest,
    { ...query, withoutPagination: true },
  );
  const eventConfigurationsOptionsQuery = useEventsConfigurationsOptions({
    applicationId: currentApplication.id,
    activityType: activityTypes.COLLECTION_SET,
    status: [eventsConfigurationStatuses.ACTIVE, eventsConfigurationStatuses.INACTIVE],
  });
  const sequentialOffersQuery = useSequentialOffersOptions({ applicationId: currentApplication.id });

  const { response: creativeTemplates } = useQuery(
    CreativesRoutes.Templates.indexRequest,
    {
      ...query,
      withoutPagination: true,
      type: [
        'Creatives::Templates::Offer',
        'Creatives::Templates::SequentialOffer',
        'Creatives::Templates::QuitOffer',
        'Creatives::Templates::Generic',
        'Creatives::Templates::CreativeIcon',
        'Creatives::Templates::ShopCreative',
      ],
    },
  );

  const formGroupName = 'configurationSegmentsAttributes';
  const {
    fields, append, handleDelete,
  } = useExtendedFieldArray({
    name: formGroupName,
    keyName: 'key',
  });

  const handleSegmentAdd = ({ segmentId }) => append({ segmentId });
  const handleSegmentEdit = ({ segmentId }) => (
    updateQuery(router, { configurationSegmentId: segmentId, creativeId: null })
  );
  const handleSegmentDelete = ({ segment, index }) => {
    handleDelete(segment, index);
  };

  // TODO: Replace everything on the page with react-query
  const isSomeLoading = [eventConfigurationsOptionsQuery, sequentialOffersQuery].some(({ isLoading }) => isLoading);
  if (isSomeLoading) return null;

  return presets && triggers && creativeTemplates && (
    <>
      <SegmentsControl
        fields={fields}
        formGroupName={formGroupName}
        renderView={({ segment }) => (
          <Tabs
            presets={presets}
            triggers={triggers}
            eventsConfigurationsOptions={eventConfigurationsOptionsQuery.data || []}
            sequentialOffersOptions={sequentialOffersQuery.data || []}
            creativeTemplates={creativeTemplates}
            segmentId={segment.segmentId}
          />
        )}
        onSegmentAdd={handleSegmentAdd}
        onSegmentEdit={handleSegmentEdit}
        onSegmentDelete={handleSegmentDelete}
      />
      <RegisteredError name="configurationSegments" />
    </>
  );
}
